import { ActionSheet } from "@liberetech/design-system";
import Button from "@liberetech/design-system/Button";
import FormInputRadio from "@liberetech/design-system/FormInputRadio";
import { useRouter } from "next/router";
import React, { useState } from "react";

import useForm from "../../../lib/useForm";
import { LanguageAlternates } from "../../Seo/Seo";

import { useTranslation } from "lib/i18n/client";

import styles from "./LayoutLanguageSelector.module.css";

const LayoutLanguageSelector: React.FC<LayoutLanguageSelectorType> = ({
  className,
  languageAlternates,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation("common");
  const { locale, push } = useRouter();
  const { inputPropsForRadio, handleSubmit } = useForm<{
    language: string;
  }>({
    translationContext: "common",
  });

  return (
    <>
      <Button
        icon={<LanguageIcon />}
        className={className}
        onClick={() => setIsOpen(true)}
      >
        {languagesMap[locale as string]}
      </Button>
      <ActionSheet
        overlayClassName={styles.actionSheet}
        title={t("languageSelector.title")}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        actions={
          <Button className={styles.submitButton} form="language-selector">
            {t("languageSelector.submit")}
          </Button>
        }
      >
        <form
          id="language-selector"
          onSubmit={handleSubmit((data) => {
            const href = languageAlternates.find(
              (lang) => lang.hrefLang === data.language,
            );
            if (href) {
              push(href.href, undefined, { locale: data.language });
            }
            setIsOpen(false);
          })}
        >
          <FormInputRadio
            {...inputPropsForRadio(
              "language",
              languageAlternates.map(({ hrefLang }) => ({
                label: languagesMap[hrefLang],
                value: hrefLang,
              })),
              { required: true, value: locale, label: " " },
            )}
            required={false}
            label={""}
          />
        </form>
      </ActionSheet>
    </>
  );
};

const LanguageIcon = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M12 .75v22.5M.75 12h22.5" />
      <path d="M12 23.25c3.55 0 6.428-5.037 6.428-11.25S15.55.75 12 .75 5.572 5.787 5.572 12 8.45 23.25 12 23.25Z" />
      <path d="M2.483 6h19.034M2.483 18h19.034" />
      <path
        d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75
12 5.787 23.25 12 23.25Z"
      />
    </svg>
  );
};

const languagesMap: { [key: string]: string } = {
  es: "Español",
  en: "English",
  fr: "Français",
  pt: "Português",
  it: "Italiano",
};

type LayoutLanguageSelectorType = {
  className?: string;
  languageAlternates: LanguageAlternates;
};

export default LayoutLanguageSelector;
