import { toZonedTime, fromZonedTime } from "date-fns-tz";

export class ZonedDate {
  zonedDate: Date;
  currentTimezone: string;

  private constructor(zonedTime: Date, timezone: string) {
    this.zonedDate = zonedTime;
    this.currentTimezone = timezone;
  }

  static userTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static fromNow(timezone: string) {
    return new ZonedDate(toZonedTime(new Date(), timezone), timezone);
  }

  static fromLocal(localDate: Date | string, timezone: string) {
    return new ZonedDate(new Date(localDate), timezone);
  }

  static fromUtc(utcDate: Date | string, timezone: string) {
    return new ZonedDate(toZonedTime(utcDate, timezone), timezone);
  }

  get date(): Date {
    return this.zonedDate;
  }

  get timezone(): string {
    return this.currentTimezone;
  }

  utcDate(): Date {
    return fromZonedTime(this.zonedDate, this.currentTimezone);
  }

  /* fn() method is used as compatibility layer for date-fns functions modifying the date.
    First argument will be the modifier function, and the following arguments will be passed to that function.
    Example usage:
      const date1 = ZonedDate.fromNow(asset.timezone).fn(addDays, 1);
      const date2 = ZonedDate.fromNow(asset.timezone).fn(startOfMonth);
  */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  fn(fn: Function, ...args: any[]): ZonedDate {
    return new ZonedDate(fn(this.zonedDate, ...args), this.currentTimezone);
  }
}
