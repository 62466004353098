// Code generated by codegen. DO NOT EDIT!
//
// Use 'yarn codegen' to regenerate this file based on schema and
// file defined operations.

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

import * as Types from "../../../gql/types";

import { fetcher } from "lib/client/graphql";
export type UseNavBarContextReservationFragment = {
  __typename?: "Reservation";
  id: string;
  status: Types.ReservationStatus;
  checkinTime: string;
  checkoutTime: string;
  asset: { __typename?: "Asset"; id: string; timezone: string };
  accesses: Array<{
    __typename?: "ReservationAccess";
    openingMethods: Array<
      | { __typename: "OpeningMethodMobileKey" }
      | { __typename: "OpeningMethodPIN" }
      | { __typename: "OpeningMethodRemote" }
      | { __typename: "OpeningMethodStandalone" }
    > | null;
  }> | null;
};

export type UseNavBarContextCurrentReservationQueryVariables = Types.Exact<{
  reservationId: Types.Scalars["ID"]["input"];
}>;

export type UseNavBarContextCurrentReservationQuery = {
  __typename?: "Query";
  reservation: {
    __typename?: "Reservation";
    id: string;
    status: Types.ReservationStatus;
    checkinTime: string;
    checkoutTime: string;
    asset: { __typename?: "Asset"; id: string; timezone: string };
    accesses: Array<{
      __typename?: "ReservationAccess";
      openingMethods: Array<
        | { __typename: "OpeningMethodMobileKey" }
        | { __typename: "OpeningMethodPIN" }
        | { __typename: "OpeningMethodRemote" }
        | { __typename: "OpeningMethodStandalone" }
      > | null;
    }> | null;
  } | null;
};

export type UseNavBarContextCurrentPurchaseQueryVariables = Types.Exact<{
  purchaseId: Types.Scalars["ID"]["input"];
}>;

export type UseNavBarContextCurrentPurchaseQuery = {
  __typename?: "Query";
  purchase: {
    __typename?: "Purchase";
    id: string;
    reservations: {
      __typename?: "ReservationConnection";
      nodes: Array<{
        __typename?: "Reservation";
        id: string;
        status: Types.ReservationStatus;
        checkinTime: string;
        checkoutTime: string;
        asset: { __typename?: "Asset"; id: string; timezone: string };
        accesses: Array<{
          __typename?: "ReservationAccess";
          openingMethods: Array<
            | { __typename: "OpeningMethodMobileKey" }
            | { __typename: "OpeningMethodPIN" }
            | { __typename: "OpeningMethodRemote" }
            | { __typename: "OpeningMethodStandalone" }
          > | null;
        }> | null;
      }>;
    } | null;
  } | null;
};

export type UseNavBarContextCloseReservationsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ReservationFilter>;
}>;

export type UseNavBarContextCloseReservationsQuery = {
  __typename?: "Query";
  reservations: {
    __typename?: "ReservationConnection";
    nodes: Array<{
      __typename?: "Reservation";
      id: string;
      status: Types.ReservationStatus;
      checkinTime: string;
      checkoutTime: string;
      asset: { __typename?: "Asset"; id: string; timezone: string };
      accesses: Array<{
        __typename?: "ReservationAccess";
        openingMethods: Array<
          | { __typename: "OpeningMethodMobileKey" }
          | { __typename: "OpeningMethodPIN" }
          | { __typename: "OpeningMethodRemote" }
          | { __typename: "OpeningMethodStandalone" }
        > | null;
      }> | null;
    }>;
  } | null;
};

export const UseNavBarContextReservationFragmentDoc = `
    fragment UseNavBarContextReservation on Reservation {
  id
  asset {
    id
    timezone
  }
  status
  checkinTime
  checkoutTime
  accesses {
    openingMethods {
      __typename
    }
  }
}
    `;
export const UseNavBarContextCurrentReservationDocument = `
    query UseNavBarContextCurrentReservation($reservationId: ID!) {
  reservation(id: $reservationId) {
    ...UseNavBarContextReservation
  }
}
    ${UseNavBarContextReservationFragmentDoc}`;
export const useUseNavBarContextCurrentReservationQuery = <
  TData = UseNavBarContextCurrentReservationQuery,
  TError = unknown,
>(
  variables: UseNavBarContextCurrentReservationQueryVariables,
  options?: UseQueryOptions<
    UseNavBarContextCurrentReservationQuery,
    TError,
    TData
  >,
) =>
  useQuery<UseNavBarContextCurrentReservationQuery, TError, TData>(
    ["UseNavBarContextCurrentReservation", variables],
    fetcher<
      UseNavBarContextCurrentReservationQuery,
      UseNavBarContextCurrentReservationQueryVariables
    >(UseNavBarContextCurrentReservationDocument, variables),
    options,
  );

useUseNavBarContextCurrentReservationQuery.getKey = (
  variables: UseNavBarContextCurrentReservationQueryVariables,
) => ["UseNavBarContextCurrentReservation", variables];
export const useInfiniteUseNavBarContextCurrentReservationQuery = <
  TData = UseNavBarContextCurrentReservationQuery,
  TError = unknown,
>(
  pageParamKey: keyof UseNavBarContextCurrentReservationQueryVariables,
  variables: UseNavBarContextCurrentReservationQueryVariables,
  options?: UseInfiniteQueryOptions<
    UseNavBarContextCurrentReservationQuery,
    TError,
    TData
  >,
) => {
  return useInfiniteQuery<
    UseNavBarContextCurrentReservationQuery,
    TError,
    TData
  >(
    ["UseNavBarContextCurrentReservation.infinite", variables],
    (metaData) =>
      fetcher<
        UseNavBarContextCurrentReservationQuery,
        UseNavBarContextCurrentReservationQueryVariables
      >(UseNavBarContextCurrentReservationDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteUseNavBarContextCurrentReservationQuery.getKey = (
  variables: UseNavBarContextCurrentReservationQueryVariables,
) => ["UseNavBarContextCurrentReservation.infinite", variables];
export const UseNavBarContextCurrentPurchaseDocument = `
    query UseNavBarContextCurrentPurchase($purchaseId: ID!) {
  purchase(id: $purchaseId) {
    id
    reservations(first: 1) {
      nodes {
        ...UseNavBarContextReservation
      }
    }
  }
}
    ${UseNavBarContextReservationFragmentDoc}`;
export const useUseNavBarContextCurrentPurchaseQuery = <
  TData = UseNavBarContextCurrentPurchaseQuery,
  TError = unknown,
>(
  variables: UseNavBarContextCurrentPurchaseQueryVariables,
  options?: UseQueryOptions<
    UseNavBarContextCurrentPurchaseQuery,
    TError,
    TData
  >,
) =>
  useQuery<UseNavBarContextCurrentPurchaseQuery, TError, TData>(
    ["UseNavBarContextCurrentPurchase", variables],
    fetcher<
      UseNavBarContextCurrentPurchaseQuery,
      UseNavBarContextCurrentPurchaseQueryVariables
    >(UseNavBarContextCurrentPurchaseDocument, variables),
    options,
  );

useUseNavBarContextCurrentPurchaseQuery.getKey = (
  variables: UseNavBarContextCurrentPurchaseQueryVariables,
) => ["UseNavBarContextCurrentPurchase", variables];
export const useInfiniteUseNavBarContextCurrentPurchaseQuery = <
  TData = UseNavBarContextCurrentPurchaseQuery,
  TError = unknown,
>(
  pageParamKey: keyof UseNavBarContextCurrentPurchaseQueryVariables,
  variables: UseNavBarContextCurrentPurchaseQueryVariables,
  options?: UseInfiniteQueryOptions<
    UseNavBarContextCurrentPurchaseQuery,
    TError,
    TData
  >,
) => {
  return useInfiniteQuery<UseNavBarContextCurrentPurchaseQuery, TError, TData>(
    ["UseNavBarContextCurrentPurchase.infinite", variables],
    (metaData) =>
      fetcher<
        UseNavBarContextCurrentPurchaseQuery,
        UseNavBarContextCurrentPurchaseQueryVariables
      >(UseNavBarContextCurrentPurchaseDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteUseNavBarContextCurrentPurchaseQuery.getKey = (
  variables: UseNavBarContextCurrentPurchaseQueryVariables,
) => ["UseNavBarContextCurrentPurchase.infinite", variables];
export const UseNavBarContextCloseReservationsDocument = `
    query UseNavBarContextCloseReservations($filter: ReservationFilter) {
  reservations(filter: $filter, orderBy: [{direction: ASC, field: CHECKIN_TIME}]) {
    nodes {
      ...UseNavBarContextReservation
    }
  }
}
    ${UseNavBarContextReservationFragmentDoc}`;
export const useUseNavBarContextCloseReservationsQuery = <
  TData = UseNavBarContextCloseReservationsQuery,
  TError = unknown,
>(
  variables?: UseNavBarContextCloseReservationsQueryVariables,
  options?: UseQueryOptions<
    UseNavBarContextCloseReservationsQuery,
    TError,
    TData
  >,
) =>
  useQuery<UseNavBarContextCloseReservationsQuery, TError, TData>(
    variables === undefined
      ? ["UseNavBarContextCloseReservations"]
      : ["UseNavBarContextCloseReservations", variables],
    fetcher<
      UseNavBarContextCloseReservationsQuery,
      UseNavBarContextCloseReservationsQueryVariables
    >(UseNavBarContextCloseReservationsDocument, variables),
    options,
  );

useUseNavBarContextCloseReservationsQuery.getKey = (
  variables?: UseNavBarContextCloseReservationsQueryVariables,
) =>
  variables === undefined
    ? ["UseNavBarContextCloseReservations"]
    : ["UseNavBarContextCloseReservations", variables];
export const useInfiniteUseNavBarContextCloseReservationsQuery = <
  TData = UseNavBarContextCloseReservationsQuery,
  TError = unknown,
>(
  pageParamKey: keyof UseNavBarContextCloseReservationsQueryVariables,
  variables?: UseNavBarContextCloseReservationsQueryVariables,
  options?: UseInfiniteQueryOptions<
    UseNavBarContextCloseReservationsQuery,
    TError,
    TData
  >,
) => {
  return useInfiniteQuery<
    UseNavBarContextCloseReservationsQuery,
    TError,
    TData
  >(
    variables === undefined
      ? ["UseNavBarContextCloseReservations.infinite"]
      : ["UseNavBarContextCloseReservations.infinite", variables],
    (metaData) =>
      fetcher<
        UseNavBarContextCloseReservationsQuery,
        UseNavBarContextCloseReservationsQueryVariables
      >(UseNavBarContextCloseReservationsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteUseNavBarContextCloseReservationsQuery.getKey = (
  variables?: UseNavBarContextCloseReservationsQueryVariables,
) =>
  variables === undefined
    ? ["UseNavBarContextCloseReservations.infinite"]
    : ["UseNavBarContextCloseReservations.infinite", variables];
