import { ActionSheet } from "@liberetech/design-system";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { lte } from "semver";

import { useDevice } from "components/Context/PageContext";
import NextLinkButton from "components/NextLinkButton/NextLinkButton";
import useActionSelector from "components/useActionSelector/useActionSelector";
import { Trans, useTranslation } from "lib/i18n/client";
import { captureError } from "lib/logger";

import useNavBarContext from "./useNavBarContext";

const LayoutNavBarKeyAction: React.FC<{
  onRequestClose: () => void;
  isOpen: boolean;
}> = ({ onRequestClose, isOpen }) => {
  const { t } = useTranslation("common");
  const { push } = useRouter();
  const navBarContext = useNavBarContext();
  const { nativeAppVersion, notifyNativeApp } = useDevice();
  const { currentAction, isActionOpen, closeAction, setAction } =
    useActionSelector<{ type: "nativeAppUpdatePrompt" }>();

  useEffect(() => {
    if (isOpen && !navBarContext.isLoading && nativeAppVersion) {
      if (navBarContext.isStandaloneAccess) {
        push(
          `/myplace/reservation/${navBarContext.reservationId}?action=showKey`,
        );
        onRequestClose();
        return;
      }
      try {
        if (lte(nativeAppVersion, "1.1.0")) {
          setAction({ type: "nativeAppUpdatePrompt" });
        } else {
          notifyNativeApp("keyring_opened");
          onRequestClose();
        }
      } catch (err) {
        captureError(err);
        onRequestClose();
      }
    }
  }, [isOpen, navBarContext.isLoading, nativeAppVersion]);

  return (
    <>
      {currentAction?.type === "nativeAppUpdatePrompt" && (
        <ActionSheet
          isOpen={isActionOpen && isOpen}
          onRequestClose={() => {
            closeAction();
            onRequestClose();
          }}
          title={t("navbar.nativeApp.updatePrompt.title")}
          actions={
            <NextLinkButton
              locale={false}
              href={"/share/XXXX-navbar-app-download"}
            >
              {t("navbar.nativeApp.updatePrompt.downloadButton")}
            </NextLinkButton>
          }
        >
          <p>
            <Trans
              i18nKey={"navbar.nativeApp.updatePrompt.content1"}
              components={{ strong: <strong /> }}
            />
          </p>
          <p>
            <Trans i18nKey={"navbar.nativeApp.updatePrompt.content2"} />
          </p>
        </ActionSheet>
      )}
    </>
  );
};

export default LayoutNavBarKeyAction;
