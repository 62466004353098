import { format, parse, isValid } from "date-fns";

import { Locale } from "types";

const formatMap = {
  es: "dd/MM/yyyy",
  fr: "dd/MM/yyyy",
  pt: "dd/MM/yyyy",
  en: "yyyy/MM/dd",
  it: "dd/MM/yyyy",
};

const defaultFormat = "yyyy/MM/dd";
const apiFormat = "yyyy-MM-dd";

export const fromApiDate = (date: string, locale: Locale): string => {
  if (!date) {
    return "";
  }
  return format(fromApiDateToDate(date), formatForLocale(locale));
};

export const toApiDate = (date: string, locale: Locale): string | null => {
  try {
    const dateObj = toDate(date, locale);
    if (!dateObj) {
      return null;
    }
    return format(dateObj, apiFormat);
  } catch (e) {
    return null;
  }
};

export const toDate = (date: string, locale: Locale): Date | null => {
  try {
    const dateObj = parse(date, formatForLocale(locale), new Date());
    return isValid(dateObj) ? dateObj : null;
  } catch (e) {
    return null;
  }
};

export const fromDate = (date: Date, locale: Locale): string | null => {
  return format(date, formatForLocale(locale));
};

export const fromDateToApiDate = (date: Date): string | null => {
  return format(date, apiFormat);
};

export function fromApiDateToDate(date: string): Date {
  return parse(date, apiFormat, new Date());
}

const formatForLocale = (locale: Locale): string =>
  formatMap[locale] || defaultFormat;

export const maskedFormat = (locale: Locale): string =>
  (formatMap[locale] || defaultFormat).replace(/\w/g, "0");
