import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

// eslint-disable-next-line no-restricted-imports
import {
  isNativeApp,
  nativeAppTrackingStatus,
  nativeAppVersion,
} from "lib/device";
import { updateGtagConsent } from "lib/gtag";
import {
  allCookieGroupsV2,
  TrackingContextPage,
  TrackingCookieGroupV2,
  COOKIE_GROUPS_ACCEPTED,
  COOKIE_EXPIRATION,
  groupsFromCookie,
} from "lib/tracking";
import { BrandGTM } from "types";

import { useBrand } from "./BrandContext";
import { useDevice } from "./PageContext";
import { useSession } from "./SessionContext";

const SENSITIVE_QUERY_PARAMS = [
  "mtoken",
  "token",
  "number",
  "lastname",
  "returnUrl",
];

const TrackingContext = createContext<{
  trackingConfig: null | TrackingCookieGroupV2[];
  allowTracking: (groups: TrackingCookieGroupV2[]) => void;
}>({
  trackingConfig: groupsFromCookie(),
  allowTracking: () => {
    return;
  },
});

export const Provider: React.FC<{
  trackingContext: TrackingContextPage;
}> = ({ children, trackingContext }) => {
  const computedTrackingContext = useComputedTrackingContext(trackingContext);
  const { allowTracking, trackingConfig } = useTrackingPermissions();

  useUpdateTrackingContext(computedTrackingContext, trackingConfig);
  useGoogleTagManager(trackingConfig);
  usePageViewTracking(computedTrackingContext, trackingConfig);

  return (
    <TrackingContext.Provider value={{ trackingConfig, allowTracking }}>
      {children}
    </TrackingContext.Provider>
  );
};

const useTrackingPermissions = () => {
  const [trackingConfig, setTrackingConfig] = useState<
    TrackingCookieGroupV2[] | null
  >(groupsFromCookie());
  const { session } = useSession();
  const anonId = session?.anonId;

  const allowTracking = (groups: TrackingCookieGroupV2[]) => {
    // If trackingConfig not initialized will be started before mounting GTM
    if (trackingConfig) {
      updateGtagConsent("update", groups);
    }

    const expiration = new Date();
    expiration.setTime(expiration.getTime() + COOKIE_EXPIRATION);

    document.cookie = `${COOKIE_GROUPS_ACCEPTED}=${groups.join(
      ",",
    )};expires=${expiration.toUTCString()};Path=/;`;

    setTrackingConfig(groups);
  };

  useEffect(() => {
    if (trackingConfig != null || !anonId) {
      return;
    }

    const ts = nativeAppTrackingStatus();
    if (ts === "authorized") {
      allowTracking(allCookieGroupsV2);
    } else if (ts === "denied") {
      allowTracking([]);
    }
  }, [anonId]);

  return { trackingConfig, allowTracking };
};

export const useTrackingContext: UseTrackingContextType = () => {
  const { trackingConfig, allowTracking } = useContext(TrackingContext);
  const { trackImpression } = useTrackingImpression();

  return {
    isConfigured: trackingConfig != null,
    allowTracking,
    trackImpression,
  };
};

export const useTrackingContextV2: UseTrackingContextV2Type = (
  trackingContext,
) => {
  const { trackingConfig } = useContext(TrackingContext);
  const computedTrackingContext = useComputedTrackingContext(trackingContext);
  useUpdateTrackingContext(computedTrackingContext, trackingConfig);
  usePageViewTracking(computedTrackingContext, trackingConfig);
};

const useGoogleTagManager = (
  trackingConfig: TrackingCookieGroupV2[] | null,
): void => {
  const brand = useBrand();
  const { session } = useSession();
  const id = brand?.gtm?.id;
  const anonId = session?.anonId;

  const code = ({ id, env }: BrandGTM) =>
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${
    env ?? ""
  }';f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${id}');`;

  useEffect(() => {
    if (!id || !anonId || !trackingConfig) {
      return;
    }
    setTimeout(() => {
      const script = document.createElement("script");
      script.appendChild(document.createTextNode(code(brand.gtm)));
      document.body.appendChild(script);
    }, 1); // Run after tracking context is published
  }, [id, anonId, !!trackingConfig]);
};

const useComputedTrackingContext = (
  trackingContext: TrackingContextPage | null,
): TrackingContextType | null => {
  const { session } = useSession();
  const { locale } = useRouter();
  const brand = useBrand();
  const { isKiosk } = useDevice();
  const [previous, setPrevious] = useState<TrackingContextPage | null>(null);
  const [computed, setComputed] = useState<TrackingContextType | null>(null);
  const anonId = session?.anonId;

  useEffect(() => {
    if (
      !anonId ||
      !trackingContext ||
      isShallowEqual(previous || {}, trackingContext)
    ) {
      return;
    }
    setComputed({
      brand_id: brand?.id,
      language_code: locale || "en",
      device_category: isKiosk
        ? "kiosk"
        : isNativeApp()
          ? "private_app"
          : "private",
      app_version: nativeAppVersion(),
      inhabitant_id: session?.inhabitantId,
      anon_id: anonId,
      ...trackingContext,
    } as TrackingContextType);
    setPrevious(trackingContext);
  }, [trackingContext, anonId]);

  return computed;
};

const isShallowEqual = (
  object1: Record<string, unknown>,
  object2: Record<string, unknown>,
) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

const useUpdateTrackingContext = (
  trackingContext: TrackingContextType | null,
  trackingConfig: TrackingCookieGroupV2[] | null,
): void => {
  // Google Tag Manager's tracking context
  useEffect(() => {
    if (!trackingContext || !trackingConfig) {
      return;
    }

    if (!window || !window.dataLayer) {
      const script = document.createElement("script");
      script.innerHTML = `window.dataLayer.push(${JSON.stringify(
        trackingContext,
      )})`;
      document.head.insertBefore(script, document.head.childNodes[0]);
      return;
    }

    updateGtagConsent("default", trackingConfig);
    window.dataLayer.push(function (this: { reset: () => void }) {
      this?.reset();
    });
    window.dataLayer.push(trackingContext);
  }, [trackingContext, trackingConfig]);
};

const usePageViewTracking: (
  trackingContext: TrackingContextType | null,
  trackingConfig: TrackingCookieGroupV2[] | null,
) => void = (trackingContext, trackingConfig) => {
  const session = useSession();

  useEffect(() => {
    if (
      session?.session &&
      window &&
      window.dataLayer &&
      trackingContext &&
      trackingConfig
    ) {
      window.dataLayer.push({
        event: "page_view",
        page: removeSensitiveQueryParams(window.location.href),
        referrer: removeSensitiveQueryParams(document.referrer),
      });
    }
  }, [session?.session, trackingContext, !!trackingConfig]);
};

const removeSensitiveQueryParams = (url: string): string => {
  try {
    const parsedUrl = new URL(url);

    SENSITIVE_QUERY_PARAMS.forEach((param) => {
      if (parsedUrl.searchParams.has(param)) {
        parsedUrl.searchParams.delete(param);
      }
    });

    return parsedUrl.toString();
  } catch (error) {
    // If URL parsing fails, return original but without query params
    const questionMarkIndex = url.indexOf("?");
    return questionMarkIndex >= 0 ? url.substring(0, questionMarkIndex) : url;
  }
};

const useTrackingImpression: UseTrackingImpressionType = () => {
  const session = useSession();
  const [pendingImpressions, setPendingImpressions] = useState<
    Array<TrackingImpressionEvent>
  >([]);

  const pushImpression: (event: TrackingImpressionEvent) => void = ({
    category,
    label,
    location,
    value,
  }) => {
    window.dataLayer.push({
      event: "impression",
      event_location: location,
      event_category: category,
      event_label: label,
      event_value: value,
    });
  };

  useEffect(() => {
    if (session?.session) {
      setTimeout(() => {
        pendingImpressions.forEach(pushImpression);
      }, 2); // Wait until pageview event is published
    }
  }, [session]);

  return {
    trackImpression: (event) => {
      if (!event) {
        return;
      }

      if (!session?.session) {
        setPendingImpressions((prev) => [...prev, event]);
        return;
      }

      setTimeout(() => {
        pushImpression(event);
      }, 150); // Wait until pageview event is published
    },
  };
};

type TrackingContextType = {
  brand_id: string;
  language_code: string;
  device_category: string;
} & TrackingContextPage;

type UseTrackingImpressionType = () => {
  trackImpression: (event?: TrackingImpressionEvent | null) => void;
};

type TrackingImpressionEvent = {
  category: string;
  location?: string;
  label?: string;
  value?: string;
};

type UseTrackingContextType = () => {
  isConfigured: boolean;
  allowTracking: (groups: TrackingCookieGroupV2[]) => void;
  trackImpression: (event?: TrackingImpressionEvent | null) => void;
};

type UseTrackingContextV2Type = (
  trackingContext: TrackingContextPage | null,
) => void;
